// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-datenschutz-js": () => import("/Users/paul/Documents/Work/it-tektur/kunden/kraiser/kraiser-website-2019/src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("/Users/paul/Documents/Work/it-tektur/kunden/kraiser/kraiser-website-2019/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/Users/paul/Documents/Work/it-tektur/kunden/kraiser/kraiser-website-2019/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/paul/Documents/Work/it-tektur/kunden/kraiser/kraiser-website-2019/.cache/data.json")

